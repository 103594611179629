import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from "@rails/request.js"
import { Sortable } from "sortablejs"

export default class extends Controller {
  static values = {url: String}

  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  async end(event) {
  
    let id = event.item.dataset.id
    var data = new FormData()
    data.append("position", event.newIndex + 1)

    for (var key of data.entries()) {
      console.log(key[0] + ', ' + key[1]);

    //const request = new FetchRequest("patch", this.urlValue, {responseKind: "turbo-stream"})
    //const response = await request.perform()
    //const response = await patch(this.data.get(theUrl), { body: data})
    /* const response = await patch(this.data.get("url").replace(":id", id), { body: data }) */

    if (response.ok) {
      console.log('Sequence reordered')
    } else {
      console.error('error in sorting menu_headers')
    }
  }
}
}
