import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="showhide"
export default class ConferenceRegistrationController extends Controller {
  static targets = ["input", "showChapter", "cost", "badgeFirstname", "badgeLastname", "nameWarning" ]
  static values = {pricing: String}

  connect() {
    this.showCost();
  } 

  checkNames() {
    if (this.badgeFirstnameTarget.value == null || this.badgeFirstnameTarget.value == "" || this.badgeLastnameTarget.value == null || this.badgeLastnameTarget.value == "" ) {
      this.inputTarget.value = "" // prevent the select from doing anything
      this.nameWarningTarget.textContent = "Badge names are required to select a category."
    } else {
      this.showHide()
      this.showCost()
    }
  }


  showCost() {
    if (this.pricingValue == "early") {
      if (this.badgeFirstnameTarget.value != null && this.badgeFirstnameTarget.value != "" && this.badgeLastnameTarget.value != null && this.badgeLastnameTarget.value != "" ) {
        if (this.inputTarget.value == "Teacher") {
          this.costTarget.value = "125"
        } else if (this.inputTarget.value == "Teacher (Thursday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Teacher (Friday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Teacher (Saturday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Teacher (Sunday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Collegiate") {
          this.costTarget.value = "0"
        } else if (this.inputTarget.value == "Adult Guest") {
          this.costTarget.value = "12";
        } else if (this.inputTarget.value == "Non-participating Student (6+)") {
          this.costTarget.value = "6"
        } else if (this.inputTarget.value == "Senior Guest (65+)") {
          this.costTarget.value = "6"
        } else {
          this.costTarget.value = "0"
        }
      } 
    } else {
      if (this.badgeFirstnameTarget.value != null && this.badgeFirstnameTarget.value != "" && this.badgeLastnameTarget.value != null && this.badgeLastnameTarget.value != "" ) {
        if (this.inputTarget.value == "Teacher") {
          this.costTarget.value = "155"
        } else if (this.inputTarget.value == "Teacher (Thursday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Teacher (Friday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Teacher (Saturday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Teacher (Sunday only)") {
          this.costTarget.value = "65"
        } else if (this.inputTarget.value == "Collegiate") {
          this.costTarget.value = "0"
        } else if (this.inputTarget.value == "Adult Guest") {
          this.costTarget.value = "18"
        } else if (this.inputTarget.value == "Non-participating Student (6+)") {
          this.costTarget.value = "6"
        } else if (this.inputTarget.value == "Senior Guest (65+)") {
          this.costTarget.value = "6"
        } else {
          this.costTarget.value = "0"
        }
      }
    }
    this.showHide();
  }

  showHide() {
    if (this.inputTarget.value != "Collegiate") {
      this.showChapterTarget.hidden = true
    } else if (this.inputTarget.value == "Collegiate") {
      this.showChapterTarget.hidden = false
    }
  }
}
