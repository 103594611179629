import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { put } from "@rails/request.js"
// Connects to data-controller="candidate-position"
export default class extends Controller {

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.updatePosition.bind(this)
    })
  }

  async updatePosition(event) {
    const response = await put('/candidates_move', {
      body: JSON.stringify({
        sgid: event.item.dataset.sgid,
        position: event.newIndex + 1      
      })
    })
  }
}


