import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["writein1", "writein2", "checkbox", "checkbox_error" ]

    connect() {
        this.writein1Target.hidden = false;
        this.writein2Target.hidden = false;
        this.hideError();
    }

    validateWritein() {
        // count the checked checkboxes
        let count = 0;
        for (var i=0; i < this.checkboxTargets.length; i++)  {
            if (this.checkboxTargets[i].checked)  {
              count = count + 1;
            }
        }
        console.log(count);
        if (count == 1) {
            //this.writein1Target.hidden = false;
            this.writein1Target.disabled = false;
            this.writein1Target.value = "";
            //this.writein2Target.hidden = true;
            this.writein2Target.value = "disabled";
            this.writein2Target.disabled = true;
            this.showError();
        } else if (count == 2) {
            //this.writein1Target.hidden = true;
            this.writein1Target.value = "disabled";
            this.writein1Target.disabled = true;
            //this.writein2Target.hidden = true;
            this.writein2Target.value = "disabled";
            this.writein2Target.disabled = true;
            this.showError();
        } else if (count == 0) {
            //this.writein1Target.hidden = false;
            this.writein1Target.disabled = false;
            this.writein1Target.value = "";
            //this.writein2Target.hidden = false;
            this.writein2Target.disabled = false;
            this.writein2Target.value = ""
            this.hideError();
        } 
    }


    showError() {
        this.checkbox_errorTarget.hidden = false;
    }

    hideError() {
        this.checkbox_errorTarget.hidden = true;
    }




}