import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    static targets = [ "hideable" ]

    connect() {

    }


    showTargets() {
        this.hideableTargets.forEach(el => {
            el.hidden = false;
        });
    }

    hideTargets() {
        this.hideableTargets.forEach(el => {
            el.hidden = true;
        });
    }

    toggleTargets() {
        this.hideableTargets.forEach(el => {
            console.log("We are in gthe right place.")
            el.hidden = !el.hidden;
        });
    }
}