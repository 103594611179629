import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="render-response"
export default class extends Controller {
  connect() {
  }

  async getConferenceRegistrationPartial () {
    // THIS IS VERSION 2
    let confirmed = confirm("Are you sure you wish to add another registrant? You will need to enter their first and last names and choose their registration category.")
    if (confirmed) {
      const response = await get("/conference_registration_payments/conference_registration_field", {responseKind: "turbo-stream"});
    }



    // BELOW IS VERSION 1
    // const response = await get("/chamber_music_clinic_groups/chamber_music_clinic_entry_field");

    //if (response.ok) {
    //  const body = await response.html;
    //  const tasksSection = document.querySelector("#chamber_music_clinic_entries");
    //  const templateElement = document.createElement("template");
    //  templateElement.innerHTML = body;

    // tasksSection.appendChild(templateElement.content.firstElementChild);
    //}
  }
}