import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="render-response"
export default class extends Controller {
  connect() {
  }

  async getChamberMusicClinicEntryPartial () {
    // THIS IS VERSION 2
    const response = await get("/chamber_music_clinic_groups/chamber_music_clinic_entry_field", {responseKind: "turbo-stream"});



    // BELOW IS VERSION 1
    // const response = await get("/chamber_music_clinic_groups/chamber_music_clinic_entry_field");

    //if (response.ok) {
    //  const body = await response.html;
    //  const tasksSection = document.querySelector("#chamber_music_clinic_entries");
    //  const templateElement = document.createElement("template");
    //  templateElement.innerHTML = body;

    // tasksSection.appendChild(templateElement.content.firstElementChild);
    //}
  }
}