import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="whitlock-category"
export default class extends Controller {
  static targets = ["fall", "spring"]
  static values = {fallvisible: Boolean, springvisible: Boolean}

  connect() {
    if (this.fallvisibleValue) {
        this.showFall()
    } else {
        this.hideFall()
    }
    if (this.springvisibleValue == true) {
        this.showSpring()
    } else {
        this.hideSpring()
    }
  }

  showFall() {
    this.fallTarget.hidden = false;
  }
  
  hideFall() {
    this.fallTarget.hidden = true;
  }

  showSpring() {
    this.springTarget.hidden = false;
  }
  
  hideSpring() {
    this.springTarget.hidden = true;
  }

}