import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["writein", "writein_error", "radio", "instruction" ]

    connect() {
        this.hideError();
        this.hideInstruction();
    }

    validateNoRadios() {
        let error = false;
        if (this.writeinTarget.value == "" || this.writeinTarget.value == null ) {
            this.hideInstruction();
            this.hideError();
            for (var i=0; i < this.radioTargets.length; i++)  {
                this.radioTargets[i].disabled = false;
            }
            console.log("START");
        } else {
            // there is something in the text field!!!!
            
            for (var i=0; i < this.radioTargets.length; i++)  {
                if (this.radioTargets[i].checked)  {
                    // uncheck it
                    this.radioTargets[i].checked = false;
                    error = true;
                    console.log("TWOA");
                } 
            }

            // disable radio buttons, whether or not one was chosen
            for (var i=0; i < this.radioTargets.length; i++)  {
                this.radioTargets[i].disabled = true;
            }

            if (error == true) {
                // there was already a radio button selected
                // show the error message
                this.showError();
                this.hideInstruction();
            } else {
                // there was no radio button selected
                // show the instruction message
                this.showInstruction();
                this.hideError();
            }      
        }
    }

    showError() {
        this.writein_errorTarget.hidden = false;
    }

    hideError() {
        this.writein_errorTarget.hidden = true;
    }

    showInstruction() {
        this.instructionTarget.hidden = false;
    }

    hideInstruction() {
        this.instructionTarget.hidden = true;
    }




}