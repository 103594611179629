import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="participation"
export default class extends Controller {
  static targets = ["subtotal", "grandTotal", "disabled", "enabled"]

  connect() {
    this.showSubmit();
  }

  totalAll() {
    function sumThem(item) {
      total += Number(item.value);
    }

    let total = 0;
    this.subtotalTargets.forEach(sumThem);
    this.grandTotalTarget.value = total;
  }

  showSubmit() {
    if (this.grandTotalTarget.value < 20) {
      this.disabledTarget.hidden = false;
      this.enabledTarget.hidden = true;
    } else if (this.grandTotalTarget.value >= 20) {
      this.disabledTarget.hidden = true;
      this.enabledTarget.hidden = false;
    }

  }
}
