import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="participation-category"
export default class extends Controller {
  static targets = ["box", "total", "vf_select"]

  connect() {
  }

  sumBoxes() {
    let sum = 0
    for (var i=0; i < this.boxTargets.length; i++)  {
      if (this.boxTargets[i].checked)  {
        sum += 1
      }
      this.totalTarget.value = sum
    }
  }

  virtualFestivalTotal() {
    let sum = 0;
    sum = parseInt(this.vf_selectTarget.value)
    sum = Math.floor(sum/2);
    this.totalTarget.value = sum
  }

}
