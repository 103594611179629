import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="whitlock-category"
export default class extends Controller {
  static targets = ["total", "earned"]

  connect() {
  }

  sumPoints() {
    function sumThem(item) {
      total += Number(item.value);
    }

    let total = 0;
    this.earnedTargets.forEach(sumThem);
    this.totalTarget.value = total
  }


}

