import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["checkbox", "disabled", "enabled" ]

    connect() {
        this.disabledTarget.hidden = true;
        this.enabledTarget.hidden = false;
    }


    countDirectorVotes() {
        var count = 0;
        for (var i=0; i < this.checkboxTargets.length; i++)  {
            if (this.checkboxTargets[i].checked)  {
              count = count + 1;
            }
        }
        if (count > 2) {
            this.disabledTarget.hidden = false;
            this.enabledTarget.hidden = true;
        } else {
            this.disabledTarget.hidden = true;
            this.enabledTarget.hidden = false;
        }
    }

}