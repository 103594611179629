import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="whitlock-selector"
export default class extends Controller {
  static targets = ["input", "inputradio", "box", "earned" ]
  static values = {category: String}

  connect() {
  }

  showPoints() {
    if (this.categoryValue == "theory") {
      if (this.inputTarget.value == "Gold Medal") {
        this.earnedTarget.value = "30"
      } else if (this.inputTarget.value == "Silver Medal") {
        this.earnedTarget.value = "20"
      } else if (this.inputTarget.value == "Bronze Medal") {
        this.earnedTarget.value = "10"
      } else if (this.inputTarget.value == "Did Not Place") {
        this.earnedTarget.value = "0"
      } else if (this.inputTarget.value == "select medal") {
        this.earnedTarget.value = "0"
      }
    } else if (this.categoryValue == "occ") {
      if (this.inputTarget.value == "First Place") {
        this.earnedTarget.value = "50"
      } else if (this.inputTarget.value == "Second Place") {
        this.earnedTarget.value = "30"
      } else if (this.inputTarget.value == "Third Place") {
        this.earnedTarget.value = "20"
       } else if (this.inputTarget.value == "Honorable Mention") {
          this.earnedTarget.value = "10"
      } else if (this.inputTarget.value == "Did Not Place") {
        this.earnedTarget.value = "0"
      } else if (this.inputTarget.value == "select place") {
        this.earnedTarget.value = "0"
      }
    } else if (this.categoryValue == "publication") {
      if (this.inputTarget.value == "Winning Essay") {
        this.earnedTarget.value = "30"
      } else if (this.inputTarget.value == "Outstanding Essay") {
        this.earnedTarget.value = "10"
      } else if (this.inputTarget.value == "select place") {
        this.earnedTarget.value = "0"
      }
    } else if (this.categoryValue == "performancestate") {
      if (this.inputTarget.value == "First Place") {
        this.earnedTarget.value = "50"
      } else if (this.inputTarget.value == "Second Place") {
        this.earnedTarget.value = "30"
      } else if (this.inputTarget.value == "Third Place") {
        this.earnedTarget.value = "20"
       } else if (this.inputTarget.value == "Did Not Place") {
        this.earnedTarget.value = "0"
      } else if (this.inputTarget.value == "select place") {
        this.earnedTarget.value = "0"
      }
    }
  }

  boxClicked() {
    if (this.categoryValue == "wom") {
      if (this.boxTarget.checked) {
        this.earnedTarget.value = "30"
      } else {
        this.earnedTarget.value = "0"
      }
    }
  }



  radioClicked() {
    console.log("TARGET VALUE: " + this.inputradioTarget.value)

    if (this.categoryValue == "nationalcomposition") {
      for (var i=0; i < this.inputradioTargets.length; i++)  {
        if (this.inputradioTargets[i].checked)  {
          this.earnedTarget.value = this.inputradioTargets[i].value;
        }
      }
    } else if (this.categoryValue == "nationalperformance") {
      for (var i=0; i < this.inputradioTargets.length; i++)  {
        if (this.inputradioTargets[i].checked)  {
          this.earnedTarget.value = this.inputradioTargets[i].value;
        }
      }
    }
  }
}
