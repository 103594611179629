import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enrichment"
export default class extends Controller {
    static targets = ["category", "total", "card", "check", "ccTotalDue", "hideable"]
    static values = {pricing: String}

    initialize() {
        this.calculateCost()
    }

    connect() {
    }

    calculateCost() {
        if (this.categoryTarget.value === "teacher") {
            if (this.pricingValue === "early") {
                this.totalTarget.value = '35';
            } else {
                this.totalTarget.value = '50';
            }
        }
        if (this.categoryTarget.value === "collegiate") {
            this.totalTarget.value = '15';
        }
        this.updateCardTotal() 
    }  

    updateCardTotal() {
        // console.log ("ENTERING CARD TOTAL FUNCTION")
        //card_total_el = document.getElementById("ccCardTotal")
        // console.log ("CARD TOTAL ELEMENT NODE NAME: " + card_total_el.nodeName)
        //total_el = document.getElementById('grandTotal');
        //if (total_el.value) {
        //    card_total_el.value = (parseFloat(total_el.value) + (parseFloat(total_el.value) * .035)).toFixed(2);
        //}


        this.ccTotalDueTarget.value = (parseFloat(this.totalTarget.value) + (parseFloat(this.totalTarget.value) * .035)).toFixed(2);
    }

    hidecc() {
        this.hideableTarget.hidden = true;
    }

    showcc() {
        this.hideableTarget.hidden = false;
    }
}

