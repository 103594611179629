import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="conference-payment"
export default class extends Controller {
  static targets = ["bookquantity", "bookcost", "cost", "registrantTotal", "tmtef", "tmta", "event", "totalDue", "ccTotalDue", "hideable", "payCard", "payCheck"]

  connect() {
    this.sumBooks();
    this.updatePaymentMethod();
  }

  

  sumBooks() {
    this.bookcostTarget.value = this.bookquantityTarget.value * 5
  }

  sumAllItems() {

    // first the registrants
    function sumThem(item) {
      total += Number(item.value);
    }

    let total = 0;
    this.costTargets.forEach(sumThem);
    total = total + Number(this.bookcostTarget.value) + Number(this.tmtefTarget.value) + Number(this.tmtaTarget.value) + Number(this.eventTarget.value)
    this.totalDueTarget.value = total;
    this.ccTotalDueTarget.value = (total + (total * .035)).toFixed(2)
  }

  showCard() {
    this.hideableTarget.hidden = false;
  }

  hideCard() {
    this.hideableTarget.hidden = true;
  }

  updatePaymentMethod() {
    if (this.payCardTarget.checked) {
      this.showCard()
    } else {
      this.hideCard()
    }

  }


}
